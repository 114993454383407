import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import logo from "../../images/logo_no-tagline.png"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"

const HeaderNew = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "99" }}
    >
      <div className={`header shadow ${styl.header}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="header-logo">
                <Link to="/">
                  <span className="display-4 text-white">
                    <LazyLoadImage
                      className="img-fluid"
                      src={logo}
                      alt="logo"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6 col-6 text-center">
              <Navbar className="float-right header-navigation">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav>
                      <Link to="/about">About Us</Link>
                    </Nav>
                    <ul class="specialties-dropdown">
                      <li>
                        <Link to="../specialties">
                          Specialties{" "}
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                                fill="#0f2747"
                              ></path>{" "}
                            </g>
                          </svg>
                        </Link>
                        <ul>
                          <li>
                            <Link to="../technology">
                              Information Technology{" "}
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                                    fill="#0f2747"
                                  ></path>{" "}
                                </g>
                              </svg>
                            </Link>
                            <ul>
                              <li>
                                <Link to="../technology/ERPCRM">ERP/CRM</Link>
                              </li>
                              <li>
                                <Link to="../technology/data">Data</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/oncology">Radiation Oncology</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    {/* <Nav>
                      <Link to="/technology">Information Technology</Link>
                    </Nav>
                    <Nav>
                      <Link to="/oncology">Radiation Oncology</Link>
                    </Nav> */}
                    <Nav>
                      <Link to="/news">News</Link>
                    </Nav>
                    <Nav>
                      <Link to="/contact-us">Contact Us</Link>
                    </Nav>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="bmhead">
                <SideBarMenu page="new-home" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default HeaderNew
