import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import logo from "../../images/logo-oncology.png"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"

const HeaderOncology = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "99" }}
    >
      <div className={`header shadow ${styl.header}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="header-logo">
                <Link to="/oncology">
                  <span className="display-4 text-white">
                    <LazyLoadImage
                      className="img-fluid"
                      src={logo}
                      alt="logo"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6 col-6 text-center">
              <Navbar className="float-right header-navigation">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    {/* <Nav>
                      <Link to="/oncology/#about-peersource-oncology">
                        About Us
                      </Link>
                    </Nav> */}
                    <NavDropdown title="ABOUT US" id="basic-nav-dropdown">
                      <Nav.Item>
                        <Link to="/oncology/#about-peersource-oncology">
                          WHO WE ARE
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link to="/oncology/benefits">BENEFITS</Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link to="/oncology/testimonials/">TESTIMONIALS</Link>
                      </Nav.Item>
                    </NavDropdown>
                    <Nav>
                      <Link to="/oncology/#our-specialties">
                        Our Specialties
                      </Link>
                    </Nav>
                    {/* <Nav>
                      <Link to="/benefits/">BENEFITS</Link>
                    </Nav> */}
                    <Nav>
                      <Link to="/news">News</Link>
                    </Nav>
                    <Nav>
                      <Link to="/oncology/contact-us/">Contact Us</Link>
                    </Nav>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="bmhead">
                <SideBarMenu page="oncology" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default HeaderOncology
